AOS.init({
  once: false,
  disable: false,
  duration: 500,
  easing: "ease-out-cubic",
});
const carouselEl = document.querySelectorAll(".carousel");
if (carouselEl.length > 0) {
  const carousel = new Swiper(".carousel", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    grabCursor: true,
    loop: false,
    centeredSlides: false,
    initialSlide: 0,
    spaceBetween: 24,
    autoplay: {
      delay: 7000,
    },
    navigation: {
      nextEl: ".carousel-next",
      prevEl: ".carousel-prev",
    },
  });
}
